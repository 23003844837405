var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-input", {
    staticClass: "input-item-container",
    attrs: {
      type: _vm.type,
      value: _vm.value,
      size: _vm.size,
      disabled: _vm.disabled,
      placeholder: _vm.placeholder,
      maxlength: _vm.maxlength,
    },
    on: {
      input: function ($event) {
        return _vm.formatValue({ value: $event })
      },
      change: function ($event) {
        return _vm.formatValue({ value: $event, event: "change" })
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }