<!--
 * @Author: duanxin
 * @Date: 2023-07-31 16:52:30
 * @LastEditors: duanxin
 * @LastEditTime: 2023-08-11 17:48:31
 * @Description: 输入框组件，兼容字符和字符串功能
-->
<template>
  <el-input
    class="input-item-container"
    :type="type"
    :value="value"
    :size="size"
    :disabled="disabled"
    @input="formatValue({ value: $event })"
    @change="formatValue({ value: $event, event: 'change' })"
    :placeholder="placeholder"
    :maxlength="maxlength" />
</template>

<script>
import { isNaN } from "lodash";
export default {
  name: 'a-input',
  components: {},
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // 输入框类型，一般情况下不需要传递
    type: {
      default: 'text'
    },
    // 输入框显示的内容
    value: {
      default: ''
    },
    // 禁止输入
    disabled: Boolean,
    // value取值范围，仅在valueType为数字类型时生效
    range: Array,
    // value类型
    valueType: {
      default: 'string' // 可选值为：|string 字符串|number 数字|plus 正数|integer 整数|intPlus 正整数
    },
    // 保留小数位数，仅在valueType为number/plus/integer时生效
    fixed: {
      type: Number,
      default: 0
    },
    placeholder: String,
    prefixIcon: String,
    suffixIcon: String,
    size: String,
    // 输入框可输入最大长度
    maxlength: Number
  },
  data() {
    return {
      // 输入框内容匹配规则
      regExps: {
        string: /.+/, // 普通字符串
        number: /^(\d+\.?(\d*)?|-?(\d*)?\.?(\d*)?)/, // 数字（包括正数/负数/小数）
        plus: /^(\d*\.?(\d*)?|\.?\d+|\.?)/, // 正数
        integer: /^(-?\d+|-)?/, // 整数（包括正整数/负整数）
        intPlus: /^(\d+)?/ // 正整数
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    /**
    * 格式化数字范围区间
    * @param {range集合} arr
    */
    formatRange(arr) {
      arr = arr.map(val => Number(val)).sort()
      let [num1, num2] = arr
      if (arr.length < 2) num2 = 0
      if (num1 > num2) return [num1, num2]
      return [num1, num2]
    },
    /**
    * 格式化输入框内容为对应类型数据
    * @param {参数说明} key
    */
    formatValue({ value, event = 'input'}) {
      let { valueType: type, regExps, fixed, range, formatRange } = this
      fixed = Number(fixed)
      console.log('---formatValue----', { value, event, fixed })
      const reg = regExps[type]
      let matched = value.match(reg)
      if (matched?.length) value = matched[0]
      if (event === 'change' && type !== 'string') {
        value = Number(parseFloat(value).toFixed(fixed))
      }
      if (range?.length && type !== 'string' && value !== '') {
        let [num1, num2] = formatRange(range)
        value = Number(value)
        if (value < num1) value = num1
        if (value > num2) value = num2
      }
      if (isNaN(value) || value === 'NaN') value = ''
      this.$emit('change', value)
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="stylus" scoped>

</style>
